import { Button, Popover, Position } from '@blueprintjs/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import DateRangePicker from '../DateRangePicker';
import {
    dayEnd,
    dayStart,
    fromISODateString,
    toISODateString,
    toLocaleDateString,
} from '../../utils/date';
import { gettext } from '../../utils/text';
import { createDefaultShortcuts } from '../../utils/blueprint';

const TableDateFilter = ({
    dateBefore,
    changeDateBefore,
    dateAfter,
    changeDateAfter,
    representation,
}) => {
    const [dateRange, setDateRange] = useState([
        fromISODateString(dateAfter),
        fromISODateString(dateBefore),
    ]);

    const handleDateRangeChange = useCallback(
        range => {
            /* It is possible that the date that was loaded from the session store is not correctly at the
             * boundary of the day. However, as datepicker doesn't currently have time component, it will
             * preserve the time part of the date provided initially. To avoid this, force the dates to
             * end and beginning of the day respectively.
             *
             * We also had this issue with some browsers, it is not exactly clear why. */
            const [after, before] = [dayStart(range[0]), dayEnd(range[1])];
            setDateRange([after, before]);
            changeDateAfter(toISODateString(after, representation) || '');
            changeDateBefore(toISODateString(before, representation) || '');
        },
        [changeDateBefore, changeDateAfter],
    );

    return (
        <>
            <Popover position={Position.BOTTOM_LEFT}>
                <Button
                    icon="calendar"
                    rightIcon="caret-down"
                    text={
                        !dateRange[0]
                            ? gettext('Choose a date range')
                            : `${toLocaleDateString(
                                  dateRange[0],
                              )} - ${(dateRange[1] &&
                                  toLocaleDateString(dateRange[1])) ||
                                  gettext('No date')}`
                    }
                />
                <DateRangePicker
                    id="date_range"
                    allowSingleDayRange
                    value={dateRange}
                    onChange={handleDateRangeChange}
                    shortcuts={createDefaultShortcuts(true)}
                />
            </Popover>
        </>
    );
};

TableDateFilter.propTypes = {
    dateBefore: PropTypes.string,
    changeDateBefore: PropTypes.func.isRequired,
    dateAfter: PropTypes.string,
    changeDateAfter: PropTypes.func.isRequired,
    representation: PropTypes.oneOf(['complete', 'date', 'time']), // format date, time, or both.
};

TableDateFilter.defaultProps = {
    dateBefore: '',
    dateAfter: '',
    representation: 'complete',
};

export default TableDateFilter;
